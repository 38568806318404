import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { useFooterSettings } from '@querys/useFooterSettings';
import { useGlobalMessages } from '@querys/useGlobalMessages';
import { useSubscriberSettings } from '@querys/useSubscriberSettings';

import { PortableTextBlock } from '@components/sanity/portableTextBlock';
import { PageLink, FileLink } from '@components/ui/links';
import { SubscribeForm } from '@components/subscriberForms/subscribeForm';
import { DragCarousel } from '@components/carousels/dragCarousel';
import { SanityImage } from '@components/sanity/sanityImage';

const IS_AU = process.env.GATSBY_SHOP_REGION === 'AU';

export const Footer = () => {
  const { primaryLinks, secondaryLinks, contactDetails, socialImages } =
    useFooterSettings();
  const { description } = useSubscriberSettings();
  const { responsibleServiceMessage } = useGlobalMessages();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className='w-full overflow-hidden'>
        <DragCarousel>
          <a
            className='flex'
            href='https://www.instagram.com/premium_liquor/'
            target='_blank'
            rel='noreferrer'
          >
            {socialImages.map((item, index) => (
              <div
                className='flex-shrink-0 w-1/2 md:w-1/4 lg:w-1/5'
                key={index}
              >
                <div className='aspect-w-1 aspect-h-1 relative'>
                  <SanityImage
                    image={item}
                    className='absolute inset-0 object-cover'
                    width={300}
                  />
                </div>
              </div>
            ))}
          </a>
        </DragCarousel>
      </div>
      <footer className='bg-black text-white'>
        {/* Top */}
        <div className='py-10 grid grid-cols-14 gap-y-6 lg:py-20'>
          {/* Subscribe */}
          <div className='col-start-2 col-end-14 lg:col-end-6 lg:row-start-1'>
            <h4 className='blockH3 mb-one md:text-left'>{description}</h4>
            <SubscribeForm reversed />
          </div>
          {/* Contact Details */}
          <div className='flex flex-col gap-x-2 items-center col-start-2 col-end-14 lg:col-start-12 lg:items-start'>
            {primaryLinks.map(({ _type, ...item }, index) =>
              _type === 'pageLink' ? (
                <PageLink
                  {...item}
                  className='richH4 font-medium'
                  key={index}
                />
              ) : (
                _type === 'fileLink' && (
                  <FileLink
                    {...item}
                    className='richH4 font-medium'
                    key={index}
                  />
                )
              )
            )}
          </div>
          <div className='text-center md:row-start-1 col-start-2 col-end-14 lg:col-start-8 lg:col-end-11 lg:text-left'>
            <PortableTextBlock text={contactDetails} />
          </div>
        </div>
        {/* Responsible Message */}
        {responsibleServiceMessage && (
          <div className='px-gutter text-xs'>
            <PortableTextBlock text={responsibleServiceMessage} />
          </div>
        )}
        {/* Bottom */}
        <div className='px-gutter pb-10 md:grid md:grid-cols-2 md:row-start-1'>
          {/* Payment Icons */}
          <div className='md:col-start-2'>
            {!IS_AU && (
              <div className='flex justify-center mb-2 md:justify-end'>
                <StaticImage
                  src='../../images/cheers-logo.png'
                  className='w-32'
                />
              </div>
            )}
            <div className='md:flex md:justify-end'>
              <StaticImage
                src='../../images/payment-icons-au.png'
                className='md:w-96'
              />
            </div>
          </div>
          {/* Terms */}
          <div className='blockH7 text-center mt-4 md:col-start-1 md:row-start-1 md:text-left'>
            <p>
              {process.env.GATSBY_CURRENCY_MESSAGE ||
                'All prices in New Zealand Dollars.'}
            </p>
            <p>
              &copy; {currentYear} Premium Liquor, Inc. All Rights Reserved.
            </p>
            <div className='flex gap-x-4 justify-center mt-4  md:justify-start'>
              {secondaryLinks.map(({ _type, ...item }, index) =>
                _type === 'pageLink' ? (
                  <PageLink key={index} {...item} />
                ) : (
                  _type === 'fileLink' && <FileLink key={index} {...item} />
                )
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
