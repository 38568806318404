import React from "react";
import { useCartCount, useToggleCart } from "@lib/context";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export const CartToggle = () => {
  const count = useCartCount();
  const toggleCart = useToggleCart();
  return (
    <div
      onClick={toggleCart}
      className="flex gap-x-2 items-center cursor-pointer"
    >
      <span className="hidden lg:block">Cart ({count})</span>
      <span className="w-7 h-7 border-current border-2 rounded-full flex items-center justify-center font-bold text-xs lg:hidden">
        {count}
      </span>
    </div>
  );
};
