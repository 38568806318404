import { useStaticQuery, graphql } from 'gatsby';

export const useSubscriberSettings = () => {
  const { sanitySubscriberSettings } = useStaticQuery(graphql`
    query {
      sanitySubscriberSettings {
        heading
        description
        image {
          ...ImageWithPreview
        }
      }
    }
  `);
  return sanitySubscriberSettings || {};
};
