import React from "react";

import Chevron from "@svg/chevron-d.svg";

import { PageLink } from "@components/ui/links";
import { useNavState } from "./context";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const SubNavLink = ({ title, _key, openNavSectionDesk }) => {
  return (
    <div
      onClick={() => openNavSectionDesk(_key)}
      className="cursor-pointer flex items-center gap-x-2"
    >
      {title}
      <div className="self-stretch items-center flex">
        <Chevron className="fill-current w-2 pt-1" />
      </div>
    </div>
  );
};

export const DeskNav = ({ items }) => {
  const { openNavSectionDesk } = useNavState();
  return (
    <nav className="flex col-span-2 items-center justify-center text-center gap-x-10">
      {items.map(({ _type, ...rest }, index) =>
        _type === "pageLink" ? (
          <PageLink key={index} {...rest} />
        ) : (
          <SubNavLink
            key={index}
            {...rest}
            openNavSectionDesk={openNavSectionDesk}
          />
        )
      )}
    </nav>
  );
};
