import React from 'react';
import cx from 'classnames';
import axios from 'axios';

import useCookie from 'react-use-cookie';

import CancelIcon from '@svg/close.svg';

const regionsServiced = ['NZ', 'AU'];
const shopRegion = process.env.GATSBY_SHOP_REGION;

const GeoFence = () => {
  const [geoCookie, setGeoCookie] = useCookie('geo-verified', false);
  const [show, setShow] = React.useState(false);
  const [countryName, setCountryName] = React.useState(null);

  const dismissGeo = () => {
    setShow(false);
    setGeoCookie(true);
  };

  React.useEffect(() => {
    const getUserCountry = async () => {
      const userRegion = await axios
        .get(`https://api.ipapi.com/check?access_key=${process.env.GATSBY_IPAPI_KEY}`)
        .then(({ data }) => {
          setCountryName(data.country_name);
          return data.country_code;
        })
        .catch((error) => {
          console.log(error);
        });

      if (shopRegion !== userRegion && regionsServiced.includes(userRegion)) {
        setShow(true);
      }
      console.log(userRegion)
    };


    getUserCountry();
  }, []);

  if (geoCookie) return false;

  return (
    <div
      className={cx('bg-black bg-opacity-80  w-full h-full fixed top-0 left-0 z-3000', {
        hidden: !show,
        flex: show,
      })}
    >
      <div className="bg-sand-900 rounded-xl w-full max-w-2xl mx-4 relative p-6 my-10 self-center md:mx-auto lg:p-16">
        <button className="absolute top-4 right-4" onClick={dismissGeo}>
          <CancelIcon className="w-6 fill-current" />
        </button>
        <h2 className="text-2xl text-center font-medium max-w-sm mx-auto md:text-3xl">
          Shipping to {countryName}?
        </h2>
        <div className="flex justify-center mt-4">
          <a
            className="btn"
            href={
              shopRegion === 'NZ'
                ? 'https://www.premiumliquor.com.au'
                : 'https://www.premiumliquor.co.nz'
            }
          >
            Visit our {countryName} store
          </a>
        </div>
      </div>
    </div>
  );
};

export default GeoFence;
