import React from "react";
import { connectRange } from "react-instantsearch-dom";

// Prerequisite: install rheostat@4
import "rheostat/initialize";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";

import { centsToPrice } from "@lib/helpers";

const RangeSlider = ({
  min,
  max,
  currentRefinement,
  canRefine,
  refine,
  label,
}) => {
  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  React.useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement.min, currentRefinement.max]);

  if (min === max) {
    return null;
  }

  const onChange = ({ values: [min, max] }) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <div className="py-6">
      <h5 className="blockH6 font-semi-bold mb-one">{label}</h5>
      <Rheostat
        min={min}
        max={max}
        values={[currentRefinement.min, currentRefinement.max]}
        onChange={onChange}
        onValuesUpdated={onValuesUpdated}
      >
        <div className="flex justify-between pt-2">
          <div className="font-xs font-semi-bold">
            ${centsToPrice(stateMin)}
          </div>

          <div className="font-xs font-semi-bold">
            ${centsToPrice(stateMax)}
          </div>
        </div>
      </Rheostat>
    </div>
  );
};

export const CustomRangeSlider = connectRange(RangeSlider);
