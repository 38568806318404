import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { CustomClearRefinements } from './customClearRefinements';

import { centsToPrice } from '@lib/helpers';

import CancelIcon from '@svg/close.svg';

const resolveLabel = (attribute) => {
  switch (attribute) {
    case 'named_tags.brand':
      return 'Brand';
    case 'named_tags.type':
      return 'Type';
    case 'price':
      return 'Price';
    default:
      return null;
  }
};

const RefinementList = ({ items, refine, createURL, canRefine }) => {
  if (!canRefine) {
    return null;
  }
  return (
    <div className="py-6">
      <div className="flex justify-between">
        <h5 className="blockH6 font-semi-bold mb-half">Refine By</h5>
        <CustomClearRefinements />
      </div>
      <div className="grid gap-y-2">
        {items.map((item) => (
          <div key={item.label}>
            {item.items ? (
              <React.Fragment>
                <h6 className="blockH7 font-medium mb-half">{resolveLabel(item.attribute)}</h6>
                <div className="flex gap-2 flex-wrap">
                  {item.items.map((nested) => (
                    <a
                      href={createURL(nested.value)}
                      onClick={(event) => {
                        event.preventDefault();
                        refine(nested.value);
                      }}
                      className="bg-black text-white text-xs font-bold py-1 px-3 rounded-full flex items-center gap-x-2"
                    >
                      {nested.label}
                      <CancelIcon className="w-2 fill-current" />
                    </a>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              item.attribute === 'price' && (
                <a
                  href={createURL(item.value)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(item.value);
                  }}
                  className="bg-black text-white text-xs font-bold py-1 px-3 rounded-full inline-flex items-center gap-x-2"
                >
                  <span>
                    Price: ${centsToPrice(item.currentRefinement.min)} - $
                    {centsToPrice(item.currentRefinement.max)}
                  </span>
                  <CancelIcon className="w-2 fill-current" />
                </a>
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const CurrentRefinements = connectCurrentRefinements(RefinementList);
