import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import Headroom from "react-headroom";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { useNavQuery } from "@querys/useNavQuery";
import { NavContextProvider, useNavState } from "./context";

import SerachIcon from "@svg/search.svg";
import BackArrow from "@svg/arrow-l.svg";

import { CartDraw } from "@components/cart/cartDraw";
import { CartToggle } from "@components/cart/cartToggle";
import { PageLink } from "@components/ui/links";

import { HomeLink } from "./homeLink";
import { DeskNav } from "./deskNav";
import { MegaMenuDesk } from "./megaMenuDesk";
import { UspSlider } from "./uspSlider";
import { MenuToggle } from "./menuToggle";
import { MobileNav } from "./mobileNav";
import PortalModal from "@components/ui/portalModal";
import { StockistsModal } from "../stockists/stockistsModal";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const HeaderDesk = ({ hasTransparentHeader, mainMenu, minorNav }) => {
  const {
    navOpen,
    stockistsOpen,
    openStockists,
    closeStockists,
    headerPinned,
    pinHeader,
    isHovered,
    setHover,
    closeNav,
  } = useNavState();

  return (
    <>
      <Headroom
        style={{ zIndex: "2500" }}
        wrapperStyle={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
        }}
        onPin={() => pinHeader(true)}
        onUnpin={() => pinHeader(false)}
        onUnfix={() => pinHeader(false)}
      >
        <header
          className={cx(
            "bg-transparent w-full transition-colors duration-200",
            {
              "text-white":
                hasTransparentHeader && !navOpen && !headerPinned && !isHovered,
              "text-black":
                navOpen || headerPinned || !hasTransparentHeader || isHovered,
            }
          )}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {/* Top Bar */}
          <div
            className={cx(
              "grid grid-cols-3 border-b border-opacity-30 px-nav-gutter relative z-10 py-2",
              {
                "border-black":
                  !hasTransparentHeader || navOpen || headerPinned || isHovered,
                "border-white":
                  hasTransparentHeader &&
                  !navOpen &&
                  !headerPinned &&
                  !isHovered,
              }
            )}
          >
            <nav className="flex gap-x-10 text-sm">
              {minorNav.map((item, index) => (
                <PageLink {...item} key={index} />
              ))}
              <div onClick={() => openStockists()} className="cursor-pointer">
                Stockists
              </div>
            </nav>
            <UspSlider />
            <nav className="flex gap-x-10 text-sm justify-end">
              <a
                href="https://checkout.premiumliquor.co.nz/account"
                target="_blank"
                rel="noreferrer"
              >
                Account
              </a>
              <CartToggle />
            </nav>
          </div>
          {/* Main Nav Area */}
          <div className="grid grid-cols-4 px-nav-gutter py-4 items-center relative z-10">
            <HomeLink />
            <DeskNav items={mainMenu} />
            <Link
              className="text-right flex justify-end items-center gap-x-2"
              to="/search"
            >
              Search <SerachIcon className="w-4 fill-current" />
            </Link>
          </div>
          <MegaMenuDesk
            items={mainMenu.filter((item) => item._type === "subNav")}
          />
        </header>
        <PortalModal isShowing={stockistsOpen}>
          <StockistsModal onClose={() => closeStockists()} />
        </PortalModal>
      </Headroom>
      {/* Header click out */}
      <AnimatePresence>
        {navOpen && (
          <motion.span
            className="bg-black bg-opacity-50 w-full h-screen fixed top-0 left-0 z-2000"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => closeNav()}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const toggleVars = {
  hidden: {
    opacity: 0,
    visibility: "hidden",
  },
  visible: {
    opacity: 1,
    visibility: "visible",
  },
};

const HeaderMobile = ({ hasTransparentHeader, mainMenu, minorNav }) => {
  const {
    toggleNav,
    navOpen,
    activeNavSection,
    closeNavSection,
    pinHeader,
    headerPinned,
    isHovered,
    closeStockists,
    stockistsOpen,
  } = useNavState();
  return (
    <>
      <Headroom
        style={{ zIndex: "2500" }}
        wrapperStyle={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
        }}
        onPin={() => pinHeader(true)}
        onUnpin={() => pinHeader(false)}
        onUnfix={() => pinHeader(false)}
      >
        <header
          className={cx("z-2500 transition-colors w-full lg:hidden", {
            "text-white": hasTransparentHeader && !navOpen && !headerPinned,
            "text-black": navOpen || !hasTransparentHeader || headerPinned,
          })}
        >
          {/* Bg */}
          <span
            className={cx(
              "w-full h-full absolute top-0 left-0 bg-sand-900 transition-opacity z-0",
              {
                "opacity-100": headerPinned || navOpen,
                "delay-700": navOpen,
                "opacity-0": !headerPinned && !navOpen,
              }
            )}
          />
          <div
            className={cx(
              "border-b border-opacity-30 pt-1 pb-2 relative z-10",
              {
                "border-black":
                  !hasTransparentHeader || navOpen || headerPinned || isHovered,
                "border-white":
                  hasTransparentHeader &&
                  !navOpen &&
                  !headerPinned &&
                  !isHovered,
              }
            )}
          >
            <UspSlider />
          </div>
          <div className="grid grid-cols-4 px-nav-gutter py-4 items-center relative z-10">
            {/* Menu Toggle */}
            <div className="grid">
              <motion.div
                initial={false}
                animate={!activeNavSection ? "visible" : "hidden"}
                className="col-start-1 row-start-1"
                variants={toggleVars}
              >
                <MenuToggle onClick={toggleNav} isOpen={navOpen} />
              </motion.div>

              <motion.div
                initial={false}
                animate={activeNavSection ? "visible" : "hidden"}
                onClick={() => closeNavSection()}
                className="col-start-1 row-start-1"
                variants={toggleVars}
              >
                <BackArrow className="w-6" />
              </motion.div>
            </div>
            {/* Home Link */}
            <div className="col-span-2 justify-center">
              <HomeLink />
            </div>
            {/*  */}
            <nav className="flex items-center justify-end gap-x-2">
              <Link className="w-7" to="/search">
                <SerachIcon className="fill-current" />
              </Link>
              <CartToggle />
            </nav>
          </div>
        </header>
      </Headroom>
      <MobileNav navOpen={navOpen} mainMenu={mainMenu} minorNav={minorNav} />
      <PortalModal isShowing={stockistsOpen}>
        <StockistsModal onClose={() => closeStockists()} />
      </PortalModal>
    </>
  );
};

export const Header = ({ hasTransparentHeader }) => {
  const { mainMenu, minorNav } = useNavQuery();
  const breakpoints = useBreakpoint();
  return (
    <NavContextProvider>
      {breakpoints.lg ? (
        <HeaderDesk
          hasTransparentHeader={hasTransparentHeader}
          mainMenu={mainMenu}
          minorNav={minorNav}
        />
      ) : (
        <HeaderMobile
          hasTransparentHeader={hasTransparentHeader}
          mainMenu={mainMenu}
          minorNav={minorNav}
        />
      )}
      <CartDraw />
    </NavContextProvider>
  );
};
