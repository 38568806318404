import React, { useState, useContext } from 'react';

const initialNavState = {
  navOpen: false,
  stockistsOpen: false,
  activeNavSection: null,
  headerPinned: false,
  isHovered: false,
};

const NavContext = React.createContext({
  nav: initialNavState,
  setNav: () => null,
});

const NavContextProvider = ({ children }) => {
  const [nav, setNav] = useState(initialNavState);

  return (
    <NavContext.Provider
      value={{
        nav,
        setNav,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

function useNavState() {
  const {
    nav: { navOpen, activeNavSection, stockistsOpen, headerPinned, isHovered },
    setNav,
  } = useContext(NavContext);

  async function toggleNav() {
    setNav((prevState) => {
      return {
        ...prevState,
        navOpen: !navOpen,
        activeNavSection: null,
      };
    });
  }

  async function closeNav() {
    setNav((prevState) => {
      return {
        ...prevState,
        navOpen: false,
        activeNavSection: null,
      };
    });
  }

  async function openNavSection(section) {
    setNav((prevState) => {
      return {
        ...prevState,
        activeNavSection: section,
      };
    });
  }
  async function closeNavSection() {
    setNav((prevState) => {
      return {
        ...prevState,
        activeNavSection: null,
      };
    });
  }

  async function openNavSectionDesk(section) {
    if (!activeNavSection) {
      toggleNav();
      openNavSection(section);
    } else if (activeNavSection === section) {
      toggleNav();
    } else {
      openNavSection(section);
    }
  }

  async function openStockists() {
    setNav((prevState) => {
      return {
        ...prevState,
        stockistsOpen: true,
      };
    });
  }
  async function closeStockists() {
    setNav((prevState) => {
      return {
        ...prevState,
        stockistsOpen: false,
      };
    });
  }

  async function pinHeader(value) {
    setNav((prevState) => {
      return {
        ...prevState,
        headerPinned: value,
      };
    });
  }
  async function setHover(value) {
    setNav((prevState) => {
      return {
        ...prevState,
        isHovered: value,
      };
    });
  }

  return {
    toggleNav,
    openNavSection,
    closeNavSection,
    navOpen,
    activeNavSection,
    closeNav,
    openNavSectionDesk,
    stockistsOpen,
    closeStockists,
    openStockists,
    headerPinned,
    pinHeader,
    isHovered,
    setHover,
  };
}

export { useNavState, NavContextProvider };
