import React, { useState } from "react";

import cx from "classnames";
import PortalModal from "@components/ui/portalModal";
import { StockistsModal } from "./stockistsModal";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
export const StoreLocator = ({
  linkText = "Stockists",
  description,
  filterResults,
  filter,
  hardMatch,
  reversed,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div
        className={cx(" cursor-pointer", {
          "btn-white": reversed,
          btn: !reversed,
        })}
        onClick={() => setOpen(true)}
      >
        {linkText}
      </div>
      <PortalModal isShowing={isOpen}>
        <StockistsModal
          onClose={() => setOpen(false)}
          description={description}
          filterResults={filterResults}
          brands={filter}
          hardMatch={hardMatch}
        />
      </PortalModal>
    </>
  );
};
