import React from 'react';
import { Link } from 'gatsby';
import { connectHits } from 'react-instantsearch-dom';

import { useSanityPath } from '@hooks/useSanityPath';

const Hit = ({ title, seo, slug, _type }) => {
  const path = useSanityPath({ slug, _type });
  const { metaDesc } = seo || {};
  return (
    <Link to={path}>
      <h4 className="blockH4 font-medium">{title}</h4>
      <p>{metaDesc}</p>
      <span className="text-lg md:text-xl font-semi-bold underline">Read More</span>
    </Link>
  );
};

const Hits = ({ hits }) => (
  <div className="px-gutter py-24">
    <div className="grid gap-y-10 divide-y-2 xl:px-64">
      {hits.map((hit) => (
        <Hit {...hit} />
      ))}
    </div>
  </div>
);

export const StandardSearchHits = connectHits(Hits);
