import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useSanityPath } from '@hooks/useSanityPath';
import ChevronRight from '@svg/chevron-r.svg';
import { useNavState } from './context';
import { PageLink } from '@components/ui/links';
import { SanityImage } from '@components/sanity/sanityImage';
import { useScrollFreeze } from '@hooks/useScrollFreeze';
import { CardSlider } from '@components/carousels/cardSlider';

const navVars = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const SubNavLink = ({ title, _key }) => {
  const { openNavSection } = useNavState();
  return (
    <div
      onClick={() => openNavSection(_key)}
      className='cursor-pointer flex justify-between items-center text-xl'
    >
      {title}
      <ChevronRight className='w-chevron' />
    </div>
  );
};

const PaneLink = ({ mobileImage, page, linkText }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} className='relative bg-black rounded-xl overflow-hidden'>
      <h4 className='absolute bottom-4 left-4 z-10 text-white text-xl font-medium'>
        {linkText}
      </h4>
      <div className='aspect-w-9 aspect-h-4 rounded-xl overflow-hidden relative z-0 opacity-60'>
        <SanityImage
          image={mobileImage}
          className='absolute inset-0 object-cover'
        />
      </div>
    </Link>
  );
};

const NavPane = ({
  _key,
  activeNavSection,
  primaryLinks,
  viewAllLink,
  promoCards,
}) => {
  const isOpen = activeNavSection === _key;
  return (
    isOpen && (
      <motion.div
        variants={navVars}
        initial='hidden'
        animate='visible'
        exit='hidden'
        key={_key}
      >
        <nav className='grid gap-y-2 px-nav-gutter pb-20 pt-header-spacer'>
          {viewAllLink && <PaneLink {...viewAllLink} />}
          {primaryLinks.map((item) => (
            <PaneLink {...item} />
          ))}
          <div className='w-full'>
            <CardSlider items={promoCards} />
          </div>
        </nav>
        {/* Promo Carousel */}
      </motion.div>
    )
  );
};

const NavInner = ({ mainMenu, minorNav }) => {
  const { activeNavSection, openStockists } = useNavState();
  const menuPanes = mainMenu.filter(({ _type }) => _type === 'subNav');
  useScrollFreeze();
  return (
    <motion.div
      variants={navVars}
      initial='hidden'
      animate='visible'
      exit='hidden'
      className='w-full h-full fixed bg-sand-900 top-0 left-0 z-2000 overflow-y-scroll text-black'
    >
      <AnimatePresence exitBeforeEnter>
        {!activeNavSection && (
          <motion.div
            className='px-nav-gutter h-full flex items-center justify-center'
            variants={navVars}
            initial='hidden'
            animate='visible'
            exit='hidden'
          >
            <nav className='grid gap-y-2 w-full'>
              {mainMenu.map(({ _type, ...rest }) =>
                _type === 'pageLink' ? (
                  <PageLink {...rest} className='text-xl' />
                ) : (
                  <SubNavLink {...rest} />
                )
              )}
              <div className='grid gap-y-2 mt-2'>
                {minorNav.map((item) => (
                  <PageLink {...item} className='text-base' />
                ))}
                <div onClick={() => openStockists()}>Stockists</div>
              </div>
            </nav>
          </motion.div>
        )}
        {menuPanes.map((item) => (
          <NavPane {...item} activeNavSection={activeNavSection} />
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export const MobileNav = ({ mainMenu, minorNav, navOpen }) => {
  return (
    <AnimatePresence>
      {navOpen && <NavInner mainMenu={mainMenu} minorNav={minorNav} />}
    </AnimatePresence>
  );
};
