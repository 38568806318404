import { useStaticQuery, graphql } from 'gatsby';

export const useCartSettings = () => {
  const { sanitySettingsCart } = useStaticQuery(graphql`
    query {
      sanitySettingsCart {
        defaultUpsells {
          ...BundleProductCard
        }
      }
    }
  `);
  return sanitySettingsCart || {};
};
