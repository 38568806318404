import React, { useContext, createContext } from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";

const ModuleContext = createContext({ inView: false });

const ModuleWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  triggerOnce = false,
  threshold = 0.5,
}) => {
  // Padding Classes
  const paddingClasses = cx({
    "pt-space-s": paddingTop === "small",
    "pt-space-m": paddingTop === "medium",
    "pt-space-l": paddingTop === "large",
    "pb-space-s": paddingBottom === "small",
    "pb-space-m": paddingBottom === "medium",
    "pb-space-l": paddingBottom === "large",
  });

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: threshold,
    triggerOnce,
  });

  return (
    <section
      className={`${className} ${paddingClasses}`}
      id={slug && slug.current}
      ref={ref}
    >
      <ModuleContext.Provider value={{ inView }}>
        {children}
      </ModuleContext.Provider>
    </section>
  );
};

const useModuleContext = () => useContext(ModuleContext);

export { ModuleWrapper, useModuleContext };
