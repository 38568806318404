import React, { useEffect } from "react";
import cx from "classnames";
import Helmet from "react-helmet";

import "@css/main.css";

import { Header } from "@components/nav/header";
import { SubscribeCTA } from "@components/subscriberForms/subscribeCTA";
import { Footer } from "@components/nav/footer";
import { AgeGate } from "@components/ageGate";
import ReactGA from "react-ga4";
import GeoFence from "./geoFence";

ReactGA.initialize("G-22K17TDHB3");

const Layout = ({
  children,
  hasTransparentHeader = false,
  reverseText = false,
  pageBg = null,
  hideSub = false,
  isProduct = false,
  overlayHeader = false,
}) => {
  useEffect(() => {
    if (pageBg) {
      document.documentElement.style.setProperty(`--pageBg`, pageBg.hex);
    }
    if (reverseText) {
      document.documentElement.style.setProperty(`--docText`, "#ffffff");
    } else {
      document.documentElement.style.setProperty(`--docText`, "#000000");
    }
    return () => {
      document.documentElement.style.setProperty(`--docText`, "#000000");
      document.documentElement.style.setProperty(`--pageBg`, "#ffffff");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageBg]);

  // Google Analytics
  useEffect(() => {
    const page =
      typeof window !== "undefined" ? window.location.pathname : null;
    if (page === null) return;

    ReactGA.send({
      hitType: "pageview",
      page,
      title: window.location.title,
    });
  }, []);

  return (
    <>
      <Helmet>
        <script
          src="https://js.afterpay.com/afterpay-1.x.js"
          data-analytics-enabled
          async
        ></script>
      </Helmet>
      <Header
        hasTransparentHeader={hasTransparentHeader}
        isProduct={isProduct}
      />
      <main
        className={cx({
          "mt-header-spacer":
            !hasTransparentHeader && !isProduct && !overlayHeader,
        })}
      >
        {children}
      </main>
      {!hideSub && <SubscribeCTA />}
      <Footer />
      <AgeGate />
      <GeoFence />
    </>
  );
};

export default Layout;
