import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => (
  <button onClick={() => refine(items)} disabled={!items.length}>
    Clear All X
  </button>
);

export const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
