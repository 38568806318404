import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";

import { useNavState } from "./context";
import { PageLink } from "@components/ui/links";

import { defaultTransition } from "@lib/animation";

import { CardSlider } from "@components/carousels/cardSlider";

const paneVariables = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...defaultTransition,
      delay: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    y: -10,
    transition: {
      ...defaultTransition,
    },
  },
};

const MenuPane = ({
  _key,
  activeNavSection,
  primaryLinks,
  primaryLinksTitle,
  promoCards,
  secondaryLinks,
  secondaryLinksTitle,
  viewAllLink,
}) => {
  const isOpen = activeNavSection === _key;
  return (
    isOpen && (
      <motion.div
        key={_key}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="px-nav-gutter grid grid-cols-4 pb-16 pt-4 relative z-20 gap-y-4"
        variants={paneVariables}
      >
        {/* Primary Links */}
        <div className="grid gap-y-2 self-start">
          <h4 className="text-sm text-gray-900">{primaryLinksTitle}</h4>
          {primaryLinks.map((item, index) => (
            <PageLink key={index} {...item} className="text-md" />
          ))}
        </div>
        {/* Secondary Links */}
        <div className="grid gap-y-2 self-start">
          <h4 className="text-sm text-gray-900">{secondaryLinksTitle}</h4>
          {secondaryLinks.map((item, index) => (
            <PageLink key={index} {...item} className="text-md" />
          ))}
        </div>
        {/* View All Link */}
        {viewAllLink && (
          <div className="col-start-1 col-end-2 self-end row-start-2">
            <PageLink {...viewAllLink} className="btn" />
          </div>
        )}
        {/* Promo Carousel */}
        <div className="col-span-2 col-start-3 row-span-2">
          <CardSlider items={promoCards} />
        </div>
      </motion.div>
    )
  );
};

export const MegaMenuDesk = ({ items }) => {
  const { navOpen, headerPinned, activeNavSection, isHovered } = useNavState();
  return (
    <>
      {/* Nav BG */}
      <motion.span
        className={cx(
          "absolute w-full h-full z-0 top-0 left-0 transition-colors duration-200 group-hover:bg-sand-900",
          {
            "bg-transparent": !navOpen && !headerPinned && !isHovered,
            "bg-sand-900": navOpen || headerPinned || isHovered,
          }
        )}
        transition={defaultTransition}
        layout
      />
      <AnimatePresence exitBeforeEnter>
        {items.map((item, index) => (
          <MenuPane {...item} key={index} activeNavSection={activeNavSection} />
        ))}
      </AnimatePresence>
    </>
  );
};
