import React from 'react';

import { useSubscriberSettings } from '@querys/useSubscriberSettings';
import { SanityImage } from '@components/sanity/sanityImage';
import { SubscribeForm } from './subscribeForm';

export const SubscribeCTA = () => {
  const { heading, description, image } = useSubscriberSettings();
  return (
    <div className="px-nav-gutter py-20 md:px-gutter text-black">
      <div className="grid grid-cols-1 bg-sand-900 px-5 rounded-lg md:grid-cols-2 md:px-10 md:items-center md:rounded-2xl">
        <div className="py-10">
          <h4 className="blockH1 mb-one">{heading}</h4>
          <h6 className="blockH4 mb-two">{description}</h6>
          <div>
            <SubscribeForm />
          </div>
        </div>
        <div>
          <SanityImage image={image} />
        </div>
      </div>
    </div>
  );
};
