import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import { SanityImage } from '@components/sanity/sanityImage';
import { ProductPrice } from '@components/product/productPrice';
import { SaleBadge } from '@components/product/saleBadge';

export const ProductGridCard = ({
  slug,
  title,
  bottleShot,
  productDetail,
  price,
  comparePrice,
  bgColor,
  isSpecial,
  specialText,
  isCarousel = false,
}) => {
  return (
    <Link
      to={`/products/${slug && slug.current}`}
      className={cx('relative grid group rounded-2xl overflow-hidden', {
        'w-4/5 flex-shrink-0 md:w-1/2': isCarousel,
      })}
    >
      {/* Sale Badge */}
      <SaleBadge
        className="absolute top-4 left-4 z-10"
        comparePrice={comparePrice}
        isSpecial={isSpecial}
        specialText={specialText}
        price={price}
      />
      {/* Opacity */}
      <span
        className="aspect-w-6 aspect-h-7 col-start-1 row-start-1 w-full opacity-50 group-hover:opacity-75 transition-all duration-700"
        style={{ backgroundColor: bgColor && bgColor.hex }}
      />
      <div className="row-start-1 col-start-1 relative z-10 self-center px-6 py-10 xl:px-10">
        {/* Bottle */}
        <div className="relative z-10 max-w-lg mx-auto">
          <SanityImage image={bottleShot} />
        </div>
        {/* Details */}
        <div className="text-center relative z-10">
          <h4 className="blockH4 font-medium">{title}</h4>
          <h5 className="blockH6 font-medium my-4">{productDetail}</h5>
          <span className="btn gap-x-2">
            <ProductPrice price={price} />
            <span>-</span>
            <span>Buy Now</span>
          </span>
        </div>
      </div>
    </Link>
  );
};
