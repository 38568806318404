import { useStaticQuery, graphql } from 'gatsby';

export const useGlobalMessages = () => {
  const { sanityGlobalMessages } = useStaticQuery(graphql`
    query {
      sanityGlobalMessages {
        shippingInformation: _rawShippingInformation
        responsibleServiceMessage: _rawResponsibleServiceMessage
        shippingUsp
        usp {
          text
          isLinked
          page {
            ...PageRef
          }
        }
      }
    }
  `);
  return sanityGlobalMessages || {};
};
