import React from 'react';
import cx from 'classnames';

export const SaleBadge = ({
  specialText = null,
  isSpecial = false,
  comparePrice = null,
  price = null,
  size = 'small',
  className,
}) => {
  const isActive = isSpecial || (comparePrice > 0 && comparePrice > price);
  const amountOff = Math.ceil(((comparePrice - price) / comparePrice) * 100);
  return (
    isActive && (
      <div
        className={cx(className, {
          'rounded-full bg-black text-white flex items-center justify-center px-2 text-center ': true,
          'w-16 h-16 subFour': size === 'small',
          'w-24 h-24 subTwo': size === 'large',
        })}
      >
        <>{specialText || `${amountOff}% off`}</>
      </div>
    )
  );
};
