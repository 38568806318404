import React from "react";
import useEmblaCarousel from "embla-carousel-react";

export const DragCarousel = ({ children }) => {
  const [viewportRef] = useEmblaCarousel({
    skipSnaps: false,
    align: "start",
    containScroll: true,
  });

  return <div ref={viewportRef}>{children}</div>;
};
