import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

// Hooks & Querys
import { useSanityPath } from "../../hooks";

// Svg
import ArrowRight from "../../svg/arrow-r.svg";

export const PageLink = ({ page, linkText, children, arrowRight, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} {...rest}>
      {children || linkText}
      {arrowRight && (
        <span>
          <ArrowRight className="w-4 fill-current" />
        </span>
      )}
    </Link>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ anchor, linkText, children, ...rest }) => {
  return (
    <ScrollLink to={anchor} offset={-100} smooth={true} {...rest}>
      {children || linkText}
    </ScrollLink>
  );
};

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" {...rest}>
      {linkText}
    </a>
  );
};

export const FileLink = ({ linkText, file, ...rest }) => {
  return (
    <a
      href={file && file.asset && file.asset.url}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {linkText}
    </a>
  );
};

export const SwitchLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case "pageLink":
        return <PageLink {...rest} />;
      case "path":
        return <PathLink {...rest} />;
      case "externalLink":
        return <ExternalLink {...rest} />;
      default:
        return null;
    }
  };
  return <LinkComponent {...rest} />;
};
