import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import CloseIcon from '@svg/close.svg';
import { cartDrawReveal } from '@lib/animation';
import {
  useCheckout,
  useCartTotals,
  useCartItems,
  useCloseCart,
  useStore,
} from '@lib/context';
import { LineItem } from './lineItem';
import { ShippingEstimate } from './shippingEstimate';
import { CartUpsells } from './cartUpsells';

const CartInner = () => {
  const openCheckout = useCheckout();
  const closeCart = useCloseCart();
  const lineItems = useCartItems();
  const { total } = useCartTotals();
  const { isAdding } = useStore();

  const [ageVerified, setAgeVerified] = useState(false);
  const [warningShown, setWarningShown] = useState(false);

  const handleAgeVerification = () => {
    setAgeVerified(!ageVerified);
  };

  const handleCheckout = () => {
    if (ageVerified) {
      setWarningShown(false);
      openCheckout();
    } else {
      setWarningShown(true);
    }
  };

  return (
    <>
      <motion.div
        initial='closed'
        animate='open'
        exit='closed'
        className={cx(
          'h-full w-full px-nav-gutter fixed top-0 right-0 bg-sand-900 z-3000 overflow-y-auto pb-24 lg:w-120 lg:px-10 text-black',
          {
            'cursor-wait': isAdding,
          }
        )}
        variants={cartDrawReveal}
      >
        {/* Cart Header */}
        <header className='py-4'>
          <div className='px-4 flex justify-between items-center'>
            <h4 className='text-2xl lg:text-4xl font-medium'>Your Cart</h4>
            <button onClick={closeCart}>
              <CloseIcon className='w-6' />
            </button>
          </div>
        </header>

        {lineItems.length > 0 ? (
          <>
            {/* line items */}
            <div className='grid gap-y-4 py-6'>
              {lineItems.map((item) => (
                <LineItem {...item} />
              ))}
            </div>
            {/* Upsells */}
            <CartUpsells lineItems={lineItems} />
            {/* Cart Totals */}
            <div className='border-current pt-2'>
              {/* Shipping Estimate */}
              <ShippingEstimate />
              {/* Totals */}
              <div className='flex justify-between font-bold  pt-4 blockH4'>
                <span>Total</span>
                <span>{total}</span>
              </div>
              <div className='relative py-10'>
                <label
                  htmlFor='ageTick'
                  className='text-[18px] ageTickContainer flex items-center gap-x-3'
                >
                  <input
                    type='checkbox'
                    name='ageTick'
                    id='ageTick'
                    onClick={handleAgeVerification}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[20px] h-[20px] bg-[rgba(0,0,0,0)] border-2 border-black block xl:w-[20px] xl:h-[20px] cursor-pointer flex-none'
                    }
                  ></span>
                  <span className='text-[18px]'>Yes I am 18 years or over</span>
                </label>
                {warningShown && (
                  <span className='text-[16px] font-bold mt-2 inline-block'>
                    You must be 18 years or older to purchase
                  </span>
                )}
              </div>
              <div className='hidden md:block'>
                <button className='btn-solid w-full' onClick={handleCheckout}>
                  Checkout
                </button>
              </div>
              <div className='w-full max-w-md mx-auto opacity-80 md:pt-10'>
                <StaticImage src='../../images/payment-icons-dark-au.png' />
              </div>
            </div>
          </>
        ) : (
          <div className='py-10'>
            <h2 className='text-center text-2xl'>Your Cart is empty</h2>
          </div>
        )}
      </motion.div>
      {/* Invisible Close Button */}
      <motion.div
        className='w-full h-full fixed bg-black bg-opacity-60 top-0 left-0 z-2500'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={closeCart}
      />
      {/* Mobile Checkout Button */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.2 } }}
        exit={{ opacity: 0 }}
        className='fixed bottom-6 left-0 w-full px-nav-gutter z-3000 md:hidden'
      >
        <button className='btn-solid large w-full' onClick={handleCheckout}>
          Checkout
        </button>
      </motion.div>
    </>
  );
};

export const CartDraw = () => {
  // Hooks

  const { cartIsOpen } = useStore();

  return <AnimatePresence>{cartIsOpen && <CartInner />}</AnimatePresence>;
};
