import React from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import cx from 'classnames';

import { useTracking } from '@lib/tracking';

export const SubscribeForm = ({ subHeading, placeholder, description, reversed = false }) => {
  const { identify } = useTracking();
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        identify(values.email);

        await fetch(`/.netlify/functions/subscribe`, {
          method: `POST`,
          body: JSON.stringify({ ...values }),
          headers: {
            'content-type': `application/json`,
          },
        }).then((res) => {
          setSubmitting(false);
          setStatus('success');
        });
      }}
    >
      {({
        onChange,
        errors,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form
          className={cx({
            'text-black': !reversed,
            'text-white': reversed,
          })}
        >
          {status === 'success' ? (
            <div className="col-span-full text-left">
              <motion.h2
                className="font-display text-2xl"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
              >
                Thanks for signing up, your 15% discount code has been sent to your email.
              </motion.h2>
            </div>
          ) : (
            <>
              <h4 className="text-lg">{subHeading}</h4>
              <div className="grid border-current border rounded-full items-center">
                <Field
                  type="text"
                  name="email"
                  placeholder={placeholder || 'Your Email'}
                  className="bg-transparent pl-4 py-2 col-start-1 row-start-1  md:px-4 border-none input-reset"
                />

                <button
                  className={cx(
                    'rounded-full px-4 text-center py-2 col-start-1 row-start-1 justify-self-end text-base font-medium md:px-6',
                    {
                      'bg-black text-white ': !reversed,
                      'bg-white text-black ': reversed,
                    }
                  )}
                  type="submit"
                >
                  Subscribe
                </button>
              </div>
              <ErrorMessage
                name="email"
                className="text-sm my-2 col-span-2 row-start-2"
                component="div"
              />
              <div className="text-md mt-2 max-w-xs">
                <p>{description}</p>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
