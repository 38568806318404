import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';

import { useGlobalMessages } from '@querys/useGlobalMessages';
import useInterval from '@hooks/useInterval';
import { useSanityPath } from '@hooks/useSanityPath';

const Slide = ({ text, isLinked, page, ...rest }) => {
  const path = useSanityPath(page);

  return (
    <motion.div {...rest} className="text-center">
      {isLinked ? (
        <Link to={path} className="uppercase font-medium text-xs tracking-wide md:text-sm">
          {text}
        </Link>
      ) : (
        <span className="uppercase font-medium text-xs tracking-wide md:text-sm">{text}</span>
      )}
    </motion.div>
  );
};

export const UspSlider = ({ timeout = 6000 }) => {
  const { usp } = useGlobalMessages();
  const [pageIndex, setPageIndex] = useState(0);
  const wrappedIndex = wrap(0, usp.length, pageIndex);

  useInterval(() => {
    setPageIndex(pageIndex + 1);
  }, timeout);

  return (
    <div>
      <AnimatePresence exitBeforeEnter>
        <Slide
          key={pageIndex}
          {...usp[wrappedIndex]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      </AnimatePresence>
    </div>
  );
};
