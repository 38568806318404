import React from 'react';

import { connectHits } from 'react-instantsearch-dom';

import { ProductCard } from '@components/product/productCard';

const Hits = ({ hits }) => (
  <div className="grid grid-cols-2 gap-x-2 gap-y-6 xl:grid-cols-3 xl:gap-10">
    {hits.map((hit) => (
      <ProductCard {...hit} />
    ))}
  </div>
);

export const ProductHits = connectHits(Hits);
