import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import cx from "classnames";

import CancelIcon from "@svg/cancel.svg";
import SearchIcon from "@svg/search.svg";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const SearchBox = ({ currentRefinement, refine, placeholder, isLarge }) => (
  <form noValidate action="" role="search" className="relative">
    <input
      type="text"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      className={cx(
        "border-b-2 border-t-0 border-l-0 border-r-0 w-full pl-0  border-current pb-3 focus:shadow-none focus:border-current focus:outline-none focus:ring-0 bg-transparent placeholder-current",
        {
          "border-b-4": isLarge,
          "text-3xl": isLarge,
          "text-lg": !isLarge,
        }
      )}
      placeholder={placeholder || "Search"}
    />
    {isLarge && (
      <div
        className={cx(
          "absolute top-1/2 transform -translate-y-1/2 right-0 transition-opacity duration-300 cursor-pointer",
          {
            "opacity-0": currentRefinement,
          }
        )}
      >
        <SearchIcon className="w-6 fill-current" />
      </div>
    )}
    <div
      onClick={() => refine("")}
      className={cx(
        "absolute top-1/2 transform -translate-y-1/2 right-0 transition-opacity duration-300 cursor-pointer",
        {
          "opacity-0": !currentRefinement,
        }
      )}
    >
      <CancelIcon
        className={cx("fill-current", {
          "w-4": !isLarge,
          "w-6": isLarge,
        })}
      />
    </div>
  </form>
);

export const CustomSearchBox = connectSearchBox(SearchBox);
