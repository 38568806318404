import { useStaticQuery, graphql } from 'gatsby';

export const useFooterSettings = () => {
  const { sanitySettingsFooter } = useStaticQuery(graphql`
    query {
      sanitySettingsFooter {
        contactDetails: _rawContactDetails
        primaryLinks {
          ... on SanityFileLink {
            _key
            _type
            linkText
            file {
              asset {
                url
              }
            }
          }
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
        secondaryLinks {
          ... on SanityFileLink {
            _key
            _type
            linkText
            file {
              asset {
                url
              }
            }
          }
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
        socialImages {
          ...ImageWithPreview
        }
      }
    }
  `);
  return sanitySettingsFooter || {};
};
