export const useTracking = () => {
  // Klaviyo Identify
  function identify(email, payload = {}) {
    global.klaviyo &&
      email &&
      global.klaviyo.push([
        'identify',
        {
          $email: email,
          ...payload,
        },
      ]);
  }
  // Klaviyo Track
  function track(action, payload = {}) {
    global.klaviyo &&
      action &&
      global.klaviyo.push([
        'track',
        action,
        {
          ...payload,
        },
      ]);
  }
  function trackViewedItem(payload = {}) {
    global.klaviyo &&
      payload &&
      global.klaviyo.push([
        'trackViewedItem',
        {
          ...payload,
        },
      ]);
  }
  return { identify, track, trackViewedItem };
};
