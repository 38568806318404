import React from "react";
import { motion } from "framer-motion";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import CloseIcon from "@svg/close.svg";
import { StockistHits } from "./stockistHits";
import { CustomSearchBox } from "@components/search";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { searchClient } from "@lib/algolia";

export const StockistsModal = ({
  onClose,
  title = "Stockists",
  description,
  brands = null,
  filterResults = false,
  hardMatch = false,
}) => {
  const BRAND_STRING = brands
    ? brands
        .map((item) => {
          return `brands:${item}`;
        })
        .join(hardMatch ? " AND " : " OR ")
    : null;

  return (
    <motion.div className="bg-sand-900 rounded-xl w-full max-w-2xl mx-4 relative p-4 lg:p-6  my-10 md:mx-auto self-start text-black">
      <button className="absolute top-4 right-4" onClick={onClose}>
        <CloseIcon className="w-6" />
      </button>
      <h4 className="blockH3 font-medium">{title}</h4>
      {description && <PortableTextBlock text={description} />}
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_STOCKISTS_INDEX}
      >
        {filterResults && (
          <Configure filters={`${BRAND_STRING}`} hitsPerPage={600} />
        )}

        <CustomSearchBox placeholder="Enter your suburb, postcode, or store name" />
        <StockistHits />
      </InstantSearch>
    </motion.div>
  );
};
