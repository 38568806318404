import React, { useState, useEffect } from "react";
import { connectSortBy } from "react-instantsearch-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import ChevronD from "@svg/chevron-d.svg";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const SortBy = ({ items, refine, createURL, currentRefinement }) => {
  const [open, setOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(null);

  useEffect(() => {
    const label = items.filter((item) => item.isRefined)[0].label;

    setCurrentLabel(label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement]);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <div className="relative w-full md:w-48 lg:w-auto">
      <span
        className="flex gap-x-2 items-center cursor-pointer justify-end md:hidden"
        onClick={toggle}
      >
        <span
          className={cx("border border-black w-3 h-3 rounded-full", {
            "bg-black": open,
          })}
        />
        <div className="flex">
          <span className="font-medium mr-1 hidden md:block">Sort:</span>
          {currentLabel}
        </div>
      </span>
      <div className="hidden md:block">
        <span
          className="btn w-full justify-between items-center"
          onClick={toggle}
        >
          <div className="flex">
            <span className="font-medium mr-1 hidden md:block">Sort:</span>
            <span>{currentLabel}</span>
          </div>
          <div className="pt-1 ml-4">
            <ChevronD className="w-2 h-2 fill-current" />
          </div>
        </span>
      </div>
      <AnimatePresence>
        {open && (
          <motion.ul
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 15 }}
            exit={{ opacity: 0, y: 40 }}
            className="absolute transform translate-y-4 bg-black text-white px-3 py-4 w-full "
          >
            {items.map((item) => (
              <li key={item.value}>
                <a
                  href={createURL(item.value)}
                  style={{ fontWeight: item.isRefined ? "bold" : "" }}
                  onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    refine(item.value);
                  }}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export const CustomSortBy = connectSortBy(SortBy);
