import React from 'react';
import { connectStats } from 'react-instantsearch-dom';

const Stats = ({ nbHits, label }) => (
  <h5 className="blockH5">
    {label || 'Shop'} {nbHits} Items
  </h5>
);

export const CustomStats = connectStats(Stats);
