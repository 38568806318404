import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useCookie from 'react-use-cookie';

export const AgeGate = () => {
  const [ageCookie, setAgeCookie] = useCookie('age-verified', false);
  const [show, setShow] = useState(false);

  const verifyAge = () => {
    setShow(false);
    setAgeCookie(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  if (ageCookie) return false;

  return (
    <div
      className={cx(
        'bg-black bg-opacity-80 w-full h-full fixed top-0 left-0 z-3000',
        {
          hidden: !show,
          flex: show,
        }
      )}
    >
      <div className='bg-sand-900 rounded-xl w-full max-w-2xl mx-4 relative p-6 my-10 self-center md:mx-auto lg:p-16 !text-black'>
        <h2 className='text-2xl text-center font-medium max-w-sm mx-auto md:text-3xl '>
          Please confirm your age. Are you 18 or older?
        </h2>
        <div className='flex justify-center pt-5 gap-x-4'>
          <button onClick={verifyAge} className='btn'>
            Yes, I'm 18+
          </button>
          <a href='https://www.google.com/' className='btn'>
            No, i am not
          </a>
        </div>
      </div>
    </div>
  );
};
