import React from "react";

import { connectHits } from "react-instantsearch-dom";

import { ProductGridCard } from "@components/product/productGridCard";

const Hits = ({ hits }) => {
  return (
    <div className="grid grid-cols-1 gap-x-2 gap-y-6 px-gutter md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-10">
      {hits.map((hit, index) => (
        <ProductGridCard {...hit} key={index} withBg />
      ))}
    </div>
  );
};

export const ProductSearchHits = connectHits(Hits);
