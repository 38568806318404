import React from 'react';

import { centsToPrice } from '@lib/helpers';

export const ProductPrice = ({ price, comparePrice, displayPackSelector = false, packQty }) => {
  return (
    <div className="price">
      <div className="inline-block">
        <span className="price--current">
          ${centsToPrice(displayPackSelector ? price * packQty?.qty : price)}
          {` ${process.env.GATSBY_CURRENCY_CODE || 'NZD'}`}
        </span>
        {!!comparePrice && (
          <span className="line-through ml-2 opacity-50">
            ${centsToPrice(displayPackSelector ? comparePrice * packQty?.qty : comparePrice)}
          </span>
        )}
      </div>
      {/* {!!comparePrice && (
        <span>{Math.ceil(((comparePrice - price) / comparePrice) * 100)}% off</span>
      )} */}
    </div>
  );
};
