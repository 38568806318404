import React, { useEffect, useCallback, useState } from "react";
import cx from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import AddIcon from "@svg/add.svg";
import { useAddItemToCart, useStore, useUpsells } from "@lib/context";
import { useCartSettings } from "@querys/useCartSettings";
import { SanityImage } from "@components/sanity/sanityImage";
import { ProductPrice } from "@components/product/productPrice";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const UpsellCard = ({ title, price, bgColor, bottleShot, variants }) => {
  const addToCart = useAddItemToCart();
  const { isAdding } = useStore();
  return (
    <div className="flex flex-shrink-0 w-full items-center relative rounded-xl overflow-hidden py-4 pr-4 md:py-6">
      <span
        style={{ backgroundColor: bgColor && bgColor.hex }}
        className={cx("absolute top-0 left-0 w-full h-full z-0", {
          " animate-pulse": isAdding,
        })}
      />
      <div className="w-24 flex-shrink-0 md:w-32">
        <div className="aspect-w-4 aspect-h-3 relative">
          <SanityImage
            image={bottleShot}
            className="asbolute w-full h-full top-0 left-0 object-contain object-bottom"
          />
        </div>
      </div>
      <div className="flex-1 relative z-10">
        <h4 className="text-sm font-medium md:text-xl">{title}</h4>
        <div className="text-xs font-medium md:text-lg md:font-bold">
          <ProductPrice price={price} />
        </div>
      </div>
      <button
        className={cx("flex-shrink-0 relative z-10", {
          "cursor-not-allowed": isAdding,
        })}
        onClick={() => addToCart(variants[0].id, 1)}
        disabled={isAdding}
      >
        <AddIcon className="w-6 fill-current md:w-8" />
      </button>
    </div>
  );
};

export const CartUpsells = () => {
  const { defaultUpsells } = useCartSettings();

  const dynamicUpsells = useUpsells();

  const activeUpsells =
    dynamicUpsells.length > 0 ? dynamicUpsells : defaultUpsells;

  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    align: "start",
    containScroll: true,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
    setScrollSnaps(embla.scrollSnapList());
  }, [activeUpsells, embla]);

  return (
    <div className="py-4 overflow-hidden">
      <h5 className="text-base font-medium mb-3 md:text-xl">
        {dynamicUpsells.length > 0
          ? "Frequently Bought Together"
          : "Most Popular"}
      </h5>
      <div ref={viewportRef}>
        <div className="flex gap-x-4">
          {activeUpsells
            .filter((item) => item.inStock)
            .map((item) => (
              <UpsellCard {...item} />
            ))}
        </div>
      </div>
      {scrollSnaps.length > 1 && (
        <nav className={cx("flex gap-x-2 items-center py-4 justify-center")}>
          {scrollSnaps.map((_, index) => (
            <div
              key={index}
              onClick={() => scrollTo(index)}
              className={cx("pg-btn", {
                selected: index === selectedIndex,
              })}
            />
          ))}
        </nav>
      )}
    </div>
  );
};
