import React from "react";
import PortableText from "react-portable-text";
import cx from "classnames";
import loadable from "@loadable/component";

// Block Components
import { PageLink, AnchorScrollLink } from "@components/ui/links";
import { StoreLocator } from "../stockists/storeLocator";
import { SanityImage } from "@components/sanity/sanityImage";

//

// Loadable Components
const RecipieCard = loadable(() => import("@components/blog/recipieCard"));

/* eslint-disable jsx-a11y/heading-has-content */
const serializers = (reversed, isBlog) => {
  return {
    h0: (props) => (
      <h1
        {...props}
        className={cx("richH0", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h1: (props) => (
      <h1
        {...props}
        className={cx("richH1", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h2: (props) => (
      <h2
        {...props}
        className={cx("richH2", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h3: (props) => (
      <h3
        {...props}
        className={cx("richH3", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h4: (props) => (
      <h4
        {...props}
        className={cx("richH4", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h5: (props) => (
      <h5
        {...props}
        className={cx("richH5", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    h6: (props) => (
      <h6
        {...props}
        className={cx("richH6", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    "super-script": (props) => <sup>{props.children}</sup>,
    "sub-script": (props) => <sub>{props.children}</sub>,
    normal: (props) => (
      <p
        {...props}
        className={cx("font-base mb-half", {
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    strong: (props) => <span {...props} className="font-medium" />,
    pageLink: (props) => (
      <PageLink
        {...props}
        className={cx("btn mb-half", {
          white: reversed,
          "lg:px-32": !!isBlog,
        })}
      />
    ),
    anchorLink: (props) => <div />,
    anchorText: (props) => (
      <AnchorScrollLink
        anchor={props.target && props.target.current}
        className="underline"
      >
        {props.children}
      </AnchorScrollLink>
    ),
    internalLink: (props) => (
      <PageLink page={props.page} className="underline">
        {props.children}{" "}
      </PageLink>
    ),
    externalLink: (props) => (
      <a
        href={props.url}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {props.children}{" "}
      </a>
    ),
    storeLocator: (props) => <StoreLocator {...props} reversed={reversed} />,
    figure: (props) => (
      <div className="mb-space-s">
        <SanityImage image={props} />
      </div>
    ),
    recipieCard: (props) => <RecipieCard {...props} />,
    ol: (props) => (
      <ol {...props} className="list-decimal list-outside pl-[15px]" />
    ),
  };
}; // eslint-disable-line

export const PortableTextBlock = ({
  text,
  className,
  reversed = false,
  isBlog = false,
}) => {
  return (
    text && (
      <PortableText
        content={text}
        serializers={serializers(reversed, isBlog)}
        className={className}
      />
    )
  );
};
