import React from "react";

import { connectHits } from "react-instantsearch-dom";

const Hit = ({ title, address, website, _geoloc }) => {
  return (
    <div className="py-4">
      <h4 className="blockH5 font-medium">{title}</h4>
      <div>{address}</div>
      {website && <a href={website}>View Website</a>}
      {_geoloc && (
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${_geoloc.lat},${_geoloc.lng}`}
          target="_blank"
          rel="noreferrer"
          className="blockH6 font-medium underline"
        >
          Get Directions
        </a>
      )}
    </div>
  );
};

const Hits = ({ hits }) => (
  <div className="grid gap-y-2 divide-y">
    {hits.map((hit) => (
      <Hit {...hit} />
    ))}
  </div>
);

export const StockistHits = connectHits(Hits);
