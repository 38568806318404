import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

export const useNavQuery = () => {
  const { sanitySettingsNav } = useStaticQuery(graphql`
    query {
      sanitySettingsNav {
        minorNav {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
        mainMenu {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
          ... on SanitySubNav {
            _key
            _type
            title
            primaryLinksTitle
            primaryLinks {
              _key
              linkText
              page {
                ...PageRef
              }
              mobileImage {
                ...ImageWithPreview
              }
            }
            viewAllLink {
              _key
              linkText
              page {
                ...PageRef
              }
              mobileImage {
                ...ImageWithPreview
              }
            }
            promoCards {
              ...FeatureCard
            }
            secondaryLinksTitle
            secondaryLinks {
              ...PageLink
            }
          }
        }
      }
    }
  `);
  return sanitySettingsNav || {};
};
