import React from 'react';

import { useCartTotals } from '@lib/context';

export const ShippingEstimate = ({
  threshhold = process.env.GATSBY_CURRENCY_CODE === 'AUD' ? 150.0 : 150.0,
}) => {
  const { rawTotal } = useCartTotals();

  return (
    <div className='border-t border-b border-black py-3  text-sm font-bold md:text-xl md:py-6'>
      {rawTotal < threshhold ? (
        <h4 className='text-center'>
          You are only ${(threshhold - rawTotal).toFixed(2)}{' '}
          {process.env.GATSBY_CURRENCY_CODE || 'NZD'} away from Free Shipping!
        </h4>
      ) : (
        <h4 className='text-center'>Shipping will be Free!</h4>
      )}
    </div>
  );
};
