import React, { useState } from 'react';

import { useRemoveItemFromCart, useUpdateItemsFromCart, client } from '../../lib/context';

import AddIcon from '@svg/add.svg';
import MinusIcon from '@svg/minus.svg';
import RemoveIcon from '@svg/cancel.svg';

export const LineItem = ({ id, title, quantity, variant, discountAllocations, key }) => {
  const { selectedOptions, price, image } = variant || {};
  const [stateQuantity, setQuantity] = useState(quantity);

  const updateItemsFromCart = useUpdateItemsFromCart();
  const removeFromCart = useRemoveItemFromCart();

  const updateQuantity = (qty) => {
    updateItemsFromCart({ id, quantity: qty });
    setQuantity(qty);
  };

  const itemImage = image
    ? client.image.helpers.imageForSize(image, {
        maxWidth: 200,
        maxHeight: 200,
      })
    : null;

  return (
    <div
      className="bg-white rounded-lg py-4 grid grid-cols-line-item gap-y-4 gap-x-4 md:gap-y-0"
      key={key}
    >
      {/* Image */}
      <div className="col-start-1 md:row-span-2">
        <div className="aspect-w-1 aspect-h-1 relative overflow-hidden">
          {itemImage && (
            <img
              src={itemImage}
              alt="Product Thumbnail"
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          )}
        </div>
      </div>
      {/* Details */}
      <div className="col-start-2 relative pr-10">
        <button onClick={() => removeFromCart(id)} className="text-sm absolute top-0 right-2">
          <RemoveIcon className="w-4 fill-current md:w-6" />
        </button>
        <h4 className="text-sm font-medium md:text-lg md:max-w-xs">{title}</h4>
        <h6 className="flex gap-x-1 text-xs font-medium md:text-sm md:py-2">
          {selectedOptions.map(
            ({ name, value }, index) =>
              name !== 'Title' && (
                <span>
                  {value} {index + 1 < selectedOptions.length && '/'}
                </span>
              )
          )}
        </h6>
        <div>
          {discountAllocations.map(
            ({ allocatedAmount: { amount }, discountApplication: { title } }) => (
              <h6 className="text-sm font-semi-bold">
                {title} (- ${parseFloat(amount).toFixed(2)})
              </h6>
            )
          )}
        </div>
      </div>
      {/* Bottom */}
      <div className="col-start-1 col-end-3 row-start-2 border-t flex justify-between items-center px-4 pt-2 md:col-start-2 md:border-none md:pl-0">
        {/* QTY */}
        <div className="flex items-center">
          <button
            onClick={() => (stateQuantity === 1 ? null : updateQuantity(stateQuantity - 1))}
            className="outline-none focus:outline-none justify-center"
          >
            <MinusIcon className="w-4 fill-current md:w-6" />
          </button>
          <input
            type="text"
            value={quantity}
            onChange={(e) => updateQuantity(parseInt(e.currentTarget.value, 10))}
            name="quantity"
            min="1"
            inputMode="numeric"
            className="bg-transparent text-center w-10 appearance-none border-none font-bold text-sm md:text-lg p-0 text-black input-reset"
            key={id}
          />
          <button
            onClick={() => updateQuantity(stateQuantity + 1)}
            className="outline-none focus:outline-none"
          >
            <AddIcon className="w-4 fill-current md:w-6" />
          </button>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-bold md:text-lg">
            ${parseFloat(price.amount * stateQuantity).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};
