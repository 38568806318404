import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import cx from "classnames";
import { FeatureCard } from "@components/cards/featureCard";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export const CardSlider = ({ items, aspectRatio = "rectangle", isPromo }) => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slideColor, setSliderColor] = useState("white");
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setSliderColor(items[embla.selectedScrollSnap()].textColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="relative">
      <div ref={viewportRef} className="w-full overflow-hidden">
        <div
          className={cx("flex", {
            " gap-x-2": !isPromo,
            "gap-x-10": isPromo,
          })}
        >
          {items.map((item, index) => (
            <FeatureCard
              {...item}
              aspectRatio={aspectRatio}
              className="w-full flex-shrink-0"
              isPromo={isPromo}
              isSlider={true}
              key={index}
            />
          ))}
        </div>
      </div>
      {scrollSnaps.length > 1 && (
        <nav
          className={cx("flex gap-x-2 items-center absolute bottom-6", {
            "text-white": slideColor === "white",
            "text-black": slideColor === "black",
            "left-10": !isPromo,
            "right-10": isPromo,
          })}
        >
          {scrollSnaps.map((_, index) => (
            <div
              key={index}
              onClick={() => scrollTo(index)}
              className={cx("pg-btn", {
                selected: index === selectedIndex,
              })}
            />
          ))}
        </nav>
      )}
    </div>
  );
};
