import React from 'react';
import { Link } from 'gatsby';

import { SanityImage } from '@components/sanity/sanityImage';
import { ProductPrice } from '@components/product/productPrice';
import { SaleBadge } from '@components/product/saleBadge';

export const ProductCard = ({
  bottleShot,
  slug,
  title,
  price,
  productDetail,
  comparePrice,
  inStock,
  _type,
}) => {
  return (
    <Link to={`/products/${slug && slug.current}`} className="relative animate-fade-in">
      <div className="absolute top-0 left-0 z-10">
        <SaleBadge price={price} comparePrice={comparePrice} />
      </div>
      <div className="aspect-w-4 aspect-h-3 relative lg:aspect-w-1 lg:aspect-h-1">
        {bottleShot ? (
          <SanityImage
            image={bottleShot}
            className="absolute w-full h-full top-0 left-0 object-contain object-bottom"
          />
        ) : (
          <span className="bg-sand-900 absolute inset-0" />
        )}
      </div>
      <div className="text-center pt-4">
        <h3 className="blockH5 font-medium mb-half max-w-sm mx-auto">{title}</h3>
        {productDetail && <h4 className="blockH7 font-medium mb-half">{productDetail}</h4>}
        <span className="buy-btn w-full justify-center">
          <ProductPrice price={price} />
          <span>-</span>
          {_type === 'product' && <span>{inStock ? 'Buy Now' : 'Out of stock'}</span>}
          {_type === 'bundleProduct' && <span>Buy Now</span>}
        </span>
      </div>
    </Link>
  );
};
