import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import cx from "classnames";

/* eslint-disable jsx-a11y/anchor-is-valid */
const RefinementList = ({ items, refine, label, canRefine }) => {
  if (!canRefine) {
    return null;
  }
  return (
    <div className="py-6">
      <h5 className="blockH6 font-semi-bold mb-one">{label}</h5>
      <div className="grid gap-y-2">
        {items.map((item) => (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
            className="flex items-center gap-x-2"
          >
            <span
              className={cx(
                "w-4 h-4 border border-black rounded-full transition-all duration-700",
                {
                  "bg-black": item.isRefined,
                }
              )}
            />
            <span>
              {item.label} ({item.count})
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export const CustomRefinementList = connectRefinementList(RefinementList);
