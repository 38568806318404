import React from "react";
import { Link } from "gatsby";
import Logo from "../../svg/logo.svg";

export const HomeLink = () => {
  return (
    <Link to="/" className="mx-auto lg:ml-0">
      <Logo className="fill-current w-24 mx-auto lg:w-32" />
    </Link>
  );
};
